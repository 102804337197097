
.Participate.End {
  font-size: 18px;
}

.Participate.End > div {
  width: 300px;
  align-self: center;
  flex: 0 0 32%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Participate.End .ChevronButton {
  padding: 8px 16px;
}

/* .Participate.End .UserCode {
  font-size: 48px;
  font-weight: bold;
} */

.Participate.End .Options {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
}

.Participate.End .Options .Spacing {
  height: 60px;
}
