
.Waiting {
  padding: var(--padding);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  font-size: 18px;
  text-transform: uppercase;
}

.Waiting .BlockGone {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0;
}

.Waiting .Block * {
  line-height: .7em;
}
.Waiting .Block > * + * {
  margin-top: 12px;
}

.Waiting .Block .User {
  font-size: 2em;
  font-weight: 700;
}

.Waiting .Block .Time {
  font-size: 1.5em;
  font-weight: 700;
}

.Waiting .Block .Curr {
  font-size: 3em;
  font-weight: 700;

  animation-name: little-chevron;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  --little-chevron-opacity-out: 0.2;
}
