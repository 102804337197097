.Sound .IconWrapper {
  display: flex;
  font-size: 20px;
}

.Sound .IconWrapper .Ring {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: solid 1.5px white;
  animation: sound-ring 2s ease-out 0s infinite;
}

@keyframes sound-ring {
  0% {
    transform: scale(1.2);
  }
  100% {
    opacity: 0;
    transform: scale(1.7);
  }
}