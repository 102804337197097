@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=block');

.DebugWebsocket {
  font-family: 'Fira Code', monospace;
  font-size: 12px;
  padding: var(--padding);
  padding-top: 64px;
  display: flex;
  flex-direction: column;
}

.DebugWebsocket .Receive {
  flex: 1;
  overflow: scroll;
  white-space: pre-wrap;
}

.DebugWebsocket > div + div {
  margin-top: 4px;
  padding-top: 4px;
  border-top: solid 1px #ccc;
}

.DebugWebsocket .Send {
  display: flex;
  flex-direction: row;
}

.DebugWebsocket .Send input {
  flex: 1;
}
