
button.Button {
  border: solid 1px #fff;
  border-radius: 4px;
  font-family: inherit;
  color: inherit;
  background-color: transparent;
  padding: 8px 16px;
  cursor: pointer;
  transition: all .1s ease-out;
}

button.Button:active {
  opacity: .66;
}

button.Button.disabled {
  opacity: .5;
  pointer-events: none;
}
