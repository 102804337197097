
.Input {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: solid 1px #fff6;
}

.Input > input {
  color: inherit;
  width: 100%;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  padding: 8px 16px;
  text-align: center;
  outline: none;
  border: none;
  letter-spacing: .05em;
}

.Input.underline {
  border: none;
  border-radius: 0;
  border-bottom: solid 1px #fff6;
}



/* CHEVRON */

.Input:not(.submitChevron) .Chevron {
  display: none;
}

.Input.submitChevron .Chevron {
  display: block;
}
.Input.submitChevron:not(.submitable) .Chevron {
  opacity: .25;
  pointer-events: none;
}
