
.Participate .Question {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: .05em;
  line-height: 1.1em;
  user-select: none;
}

.Participate .Question .Pagination {
  padding-bottom: 1em;
}

.Participate .Question .Next {
  padding-top: 1em;
  transition: all .5s cubic-bezier(0.3, 0, 0.5, 1);
}

.Participate .Question .Next.fade-out {
  opacity: .25;
  transition-duration: 0ms;
}
.Participate .Question .Next.fade-out .LittleChevron {
  animation-name: none;
}

.Participate .Question .Next.hidden {
  pointer-events: none;
  opacity: 0;
}