@import url(assets/fonts/Inconsolata/index.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, body * {
  margin: 0;
  box-sizing: border-box;
  position: relative;
}

.absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-column-stretch {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.flex-row {
  display: flex;
}

.button {
  user-select: none;
  cursor: pointer;
}