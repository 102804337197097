@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-300.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata-normal-300';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-300.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-400.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata-normal-400';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-400.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-500.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata-normal-500';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-500.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-600.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata-normal-600';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-600.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-700.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata-normal-700';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-700.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-800.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata-normal-800';
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-800.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-900.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata-normal-900';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: block;
  src: url(Inconsolata-normal-900.ttf) format('truetype');
}