
.Participate.Compute {
  font-size: 18px;
}

.Participate.Compute .Content {
  width: 300px;
  align-self: center;
  flex: 0 0 39%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  font-size: 18px;
  text-transform: uppercase;
  transition: opacity .5s cubic-bezier(.5, 0, .5, 1)
}

.Participate.Compute .Content.hidden {
  opacity: 0;
  pointer-events: none;
}