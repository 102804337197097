.Home {
  width: 100%;
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding);
  letter-spacing: .3em;
  line-height: 1.6em;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;

  transition: opacity 1.5s cubic-bezier(.5, 0, .5, 1);
}

.Home .Top,
.Home .Bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Home .Top {
  width: 240px;
}

.Home .Top span.small {
  font-size: 14px;
  margin: 12px;
}

.Home .Bottom {
  width: 300px;
}

.Home .Counter {
  font-size: 3em;
  padding: 64px 0;
}

.Home footer {
  position: fixed;
  bottom: var(--padding);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Home footer .Version {
  position: absolute;
  left: var(--padding);
  line-height: 1em;
  letter-spacing: .1em;
  font-size: 11px;
  opacity: .25;
}

.Home footer svg {
  fill: currentColor;
  width: 80px;
}
