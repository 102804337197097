.Share {
  padding: var(--padding);
}

.Share .Image {
  width: 100%;
  max-width: 400px;
  aspect-ratio: 1;
  background-size: cover;
  background-position: center;
  border: solid 1px #333;
}
