.ChooseStream {
  text-align: center;  
}
.ChooseStream > * + * {
  margin-top: 12px !important;
}
.ChooseStream > .TextField {
  margin-top: 24px !important;
}

.ChooseStream input {
  text-align: center;
}
