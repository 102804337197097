
body, .App {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #000;
}

.App {
  font-family: 'Inconsolata', monospace;
  font-weight: 500;
  color: white;
  --padding: 32px;
}

.App > footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.App > header {
  position: fixed;
  width: 100%;
  top: 0;
  padding: var(--padding);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.App .AutoPauseDot {
  position: fixed;
  --size: 6px;
  --margin: 16px;
  right: var(--margin);
  bottom: var(--margin);
  width: var(--size);
  height: var(--size);
  background-color: white;
  border-radius: 50%;
}


.LittleChevron {
  padding-right: .5em;
  animation-name: little-chevron;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  --little-chevron-opacity-out: 0.2;
}

@keyframes little-chevron {
  0% {
    opacity: 1;
  }
  50% {
    opacity: var(--little-chevron-opacity-out);
  }
  100% {
    opacity: 1;
  }
}
