
.Debug {
  display: flex;
  flex-direction: column;
}

.Debug > * + * {
  margin-top: 8px;
}

.Debug.Routes > * + * {
  margin-top: 4px;
}

