
.Experience {
  width: 100vw;
  max-width: 400px;
  height: 100vh;
}

.Participate {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: var(--padding);
}

