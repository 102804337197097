
.ShareModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0006;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--padding);
}

.ShareModal .Inner {
  background-color: #111;
  align-self: stretch;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  padding: var(--padding);
  justify-content: center;
  align-items: center;
}

.ShareModal .Image {
  width: 60%;
  aspect-ratio: 1;
  background-size: cover;
  margin-bottom: var(--padding);
}

.ShareModal .Url {
  align-self: stretch;
  padding: 16px 0;
  background-color: #222;
  border-radius: 4px;
  text-align: center;
}
