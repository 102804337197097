
.Participate.Intro {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Participate.Intro .ImageWrapper {
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 48px 32px 0 32px;
}

.Participate.Intro .Image {
  flex: 1;
  mix-blend-mode: screen;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.Participate.Intro .Texts {
  text-transform: uppercase;
  font-size: 15px;
  padding: 32px 0;
}

.Participate.Intro .Texts p + p {
  margin-top: 1em;
}
